import http from './http'

export function airports () {
  return http.get('srf-airports')
}

export function search (params) {
  return http.get('/search/srf', { params })
}

export function summary (token, params) {
  return http.post(`/search/srf/summary/${token}`, params)
}
