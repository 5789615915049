<template>
  <q-card v-ripple class="row shadow-1 cursor-pointer" role="option" :class="classes" @click="selected">
    <div class="col-sm-2 q-py-md q-pl-md">
      <img
        src="~assets/heart-logo-icon.png"
        height="52px"
      >
    </div>
    <div class="col-sm-6 q-pt-md q-pr-md text-faded">
      <div class="row bubble-top text-black">
        <div class="col-3 text-weight-medium">
          {{ result.departs_at | time }}
        </div>
        <div class="col-9">
          {{ result.legs[0].origin.name | capitalise }}
        </div>
      </div>
      <div v-if="result.legs.length === 1" class="row bubble-line">
        <div class="col-9 offset-3 text-uppercase text-caption text-green">
          {{ $tc('num.changes', 0) }}
        </div>
      </div>
      <div v-for="(leg, key) in result.legs.slice(1)" v-else :key="key" class="row bubble-change bubble-line">
        <div class="col-3 text-caption text-weight-medium">
          <span>{{ leg.departs_at | time }}</span>
        </div>
        <div class="col-9 text-caption">
          {{ leg.origin.name | capitalise }}
        </div>
      </div>
      <div class="row bubble-bottom text-black">
        <div class="col-3 text-weight-medium">
          {{ result.arrives_at | time }}
        </div>
        <div class="col-9">
          {{ result.legs[result.legs.length - 1].destination.name | capitalise }}
        </div>
      </div>
      <div class="col-12 q-my-sm text-caption">
        <span title="Duration">{{ result.duration | minutesToHours }}</span>
        <span> &middot; </span>
        <span :title="$t('train.route_number')">{{ result.legs[0].flight_number }}</span>
      </div>
    </div>
    <div class="col-sm-4 column q-pt-md q-px-md q-pb-xs pricing text-right">
      <div
        class="q-mt-auto"
        v-html="result.total_price.text"
      />
    </div>
  </q-card>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import date from 'utils/date-time'

export default {
  filters: {
    time: value => date.toCivilTime(value),
    minutesToHours (val) {
      if (val < 60) return `${val}m`
      let m = val
      let h = 0
      while (m >= 60) {
        h++
        m -= 60
      }
      return `${h}h ${m}m`
    },
    capitalise: value => value[0].toUpperCase() + value.toLowerCase().slice(1)
  },
  props: ['result'],
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    isSelected () {
      return this.stash.outbound_service_token === this.result.token || this.stash.return_service_token === this.result.token
    },
    classes () {
      return this.isSelected ? 'selected bg-primary-tint shadow-3' : null
    }
  },
  methods: {
    capitalise (string) {
      return string[0].toUpperCase() + string.toLowerCase().slice(1)
    },
    selected () {
      this.$emit('click', this.result.token)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .pricing
    border-style solid
    border-color $grey-4
    border-radius 0 !important
    border-width 0
    font-size 120%
    font-weight 450
    color black
    @media (min-width $breakpoint-md-min)
      border-left-width 1px
    @media (max-width $breakpoint-sm-min)
      border-top-width 1px

  @media (max-width $breakpoint-sm-min)
    .mobile-block
      display block
  @media (min-width $breakpoint-md-min)
    .bubble-change,
    .bubble-top,
    .bubble-line,
    .bubble-bottom
      position relative
      padding-left 8px
      &:before, &:after
        position absolute
        content ""
        background-color #DCDCDC
    .bubble-change,
    .bubble-top,
    .bubble-bottom
      &:before
        width 8px
        height 8px
        left -12px
        border-radius 100%
    .bubble-change:before
      top 6px
      border-radius 0
      transform rotate(45deg)
    .bubble-top:before
      bottom 8px
    .bubble-bottom:before
      top 8px
    .bubble-line:after
      width 2px
      top -8px
      bottom -8px
      left -9px
</style>
