<template>
  <q-page class="bg-white no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <q-card square class="bg-white">
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_flying') }}
        </q-card-section>
        <q-card-section>
          <form @submit.prevent>
            <div class="oneway-return-holder">
              <label>
                <input v-model="showReturn" type="radio" :value="false">
                {{ $tc('one_way_return', 1) }}
              </label>
              <label>
                <input v-model="showReturn" type="radio" :value="true">
                {{ $tc('one_way_return', 2) }}
              </label>
            </div>
            <m-location-search
              :value="origin"
              :label="$t('origin')"
              :query="airportsQuery"
              no-saved
              @selected="val => origin = val"
            />
            <m-location-search
              :value="destination"
              :label="$t('destination')"
              :query="airportsQuery"
              no-saved
              @selected="val => destination = val"
            />

            <leave-now
              :show-return="showReturn"
              :show-passengers="passengers"
              :show-transport-prefs="false"
              :disabled="!origin || !destination"
              :departing="depart"
              :returning="returning"
              @depart="val => depart = val"
              @return="val => returning = val"
              @passengers="val => passengers = val"
              @submit="submit"
            />
            <m-unified-users v-model="people" />
          </form>
        </q-card-section>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { airports as getAirports } from 'api/srf'
import { MLocationSearch, LeaveNow, MUnifiedUsers } from 'components/'
import { Notify } from 'quasar'
import loading from 'utils/loading'
import i18n from 'i18n'
import store from 'store'
import date from 'utils/date-time'
const { toCivilDate, newDate, addToDate } = date

export default {
  name: 'SRFSearchForm',
  components: { MLocationSearch, LeaveNow, MUnifiedUsers },
  data () {
    return {
      origin: null,
      destination: null,
      depart: toCivilDate(newDate()),
      returning: toCivilDate(addToDate(newDate(), { days: 1 })),
      passengers: { adults: 1 },
      user: null,
      requester: null,
      showReturn: true
    }
  },
  computed: {
    airports: () => store.getters['ondemand/airports'],
    people: {
      get () {
        return {
          user: this.user,
          requester: this.requester
        }
      },
      set (val) {
        this.requester = val.requester
        this.user = val.traveller
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('ondemand/clear')
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.preparing.search_form'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    next(vm => {
      getAirports()
        .then(({ data }) => {
          const airports = []
          Object.keys(data.airports).forEach(key => {
            airports.push({
              label: data.airports[key].name,
              code: data.airports[key].iata,
              place_id: data.airports[key].iata,
              icon: 'local_airport'
            })
          })
          store.dispatch('ondemand/stashProp', { path: 'airports', value: airports })
          if (to.params.origin) vm.origin = to.params.origin
          loading.stop()
        })
        .catch(() => {
          loading.stop()
          next(from)
          Notify.create(i18n.t('error.retrieving_airports'))
        })
    })
  },
  methods: {
    async airportsQuery (query) {
      return this.airports.filter(airport => {
        return airport.label.toLowerCase().startsWith(query.toLowerCase())
      })
    },
    submit () {
      const parameters = {
        origin: this.origin.value,
        destination: this.destination.value,
        depart: toCivilDate(this.depart),
        passengers: this.passengers.adults,
        user: this.user,
        requester: this.user
      }

      if (this.showReturn) parameters.return = toCivilDate(this.returning)

      this.$store.dispatch('ondemand/stash', { parameters })
      this.$router.push({ name: 'ondemand-srf-results' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .q-card
    width 100vw
    box-shadow none

.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .6)
  overflow hidden
  background-image url('~assets/backgrounds/flight.png')
  background-position: 0 30%;
  background-size: cover;
  text-overflow: clip;

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.center
  justify-content center
  display flex

.q-page
  display flex
  width 100%
  flex-direction column
  align-items center
  background white

.oneway-return-holder
  display flex
  flex-direction row
  justify-content space-around
  color black
  label
    display flex
    align-items center

input[type="radio"]
  margin 12px
  width 20px
  height 20px
  border-radius 15px
  border 2px solid #666
  &:checked
    border 2px solid convert(brandColor)
    &:after
      width 14px
      height 14px
      bottom 2px
      left 1px
      border-radius 15px
      position relative
      background-color convert(brandColor)
      content ''
      display inline-block
      visibility visible
      border 2px solid white
</style>
