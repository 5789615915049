<template>
  <q-drawer
    v-model="show"
    elevated
    side="right"
  >
    <div v-if="stash.results">
      <q-stepper
        ref="stepper"
        v-model="leg"
        done-color="primary"
        vertical
        header-nav
      >
        <q-step
          name="outbound"
          default
          title="Select outbound journey"
          header-nav
          :done="navigatable.includes('outbound')"
        >
          <button
            v-if="stash.outbound_service_token"
            @click="handleContinue"
          >
            {{ $t('continue') }}
          </button>
        </q-step>
        <q-step
          v-if="stash.results.return.length"
          name="return"
          default
          title="Select return journey"
          :header-nav="stash.outbound_service_token ? true : false"
          :done="navigatable.includes('return')"
        >
          <button
            v-if="stash.return_service_token"
            @click="handleContinue"
          >
            {{ $t('continue') }}
          </button>
        </q-step>
        <q-step
          name="summary"
          default
          title="Summary"
          :header-nav="navigatable.includes('summary')"
          :done="navigatable.includes('summary')"
        />
        <q-step
          name="payment"
          default
          title="Payment"
          :header-nav="false"
        />
        <q-step
          name="confirmation"
          default
          title="Confirmation"
          :header-nav="false"
        />
      </q-stepper>
    </div>
  </q-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      show: true
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    leg: {
      get () {
        return this.stash.leg
      },
      set (leg) {
        this.$store.dispatch('ondemand/stash', { leg })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.leg) {
      case 'return':
        availablePaths.push('outbound')
        break
      case 'summary':
        availablePaths.push('outbound', 'return', 'summary')
        break
      }
      return availablePaths
    }
  },
  methods: {
    handleContinue () {
      if (this.leg === 'outbound' && this.stash.results.return.length) {
        this.leg = 'return'
      } else {
        this.leg = 'summary'
        this.$router.push({ name: 'ondemand-srf-summary' })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
button
  border none
  color #555
  background #fff
  padding 8px 16px
  cursor pointer
  border-radius 3px
  text-transform uppercase
  text-align center
  font-weight 500
  box-shadow 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px rgba(0 0 0 0.14), 0 1px 14px rgba(0 0 0 0.12);
  &:hover
    background #eee
    color #777
  &:active
    animation click 0.1s

@keyframes click
  0%
    transform scale(1, 1)
  50%
    transform scale(0.95, 0.95)
  100%
    transform scale(1, 1)
</style>
