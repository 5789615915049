<template>
  <q-page>
    <div class="layout-padding">
      <div
        v-if="summary"
        class="row q-col-gutter-sm"
      >
        <div class="col-sm-12">
          <q-card>
            <q-card-section class="bg-primary">
              {{ $t('booking.summary') }}
            </q-card-section>
            <q-card-section>
              <div
                class="flight-summary-one"
              >
                <div class="title bg-primary" v-text="'Outbound Summary'" />
                <div class="timeline">
                  <div
                    v-for="(outboundLeg, index) in summary.outbound.legs"
                    :key="outboundLeg.flight_number"
                    :data-testid="`outbound-summary-${index}`"
                  >
                    <div class="timeline-section">
                      <div v-text="outboundLeg.route.origin.iata" />
                      <div v-text="outboundLeg.route.destination.iata" />
                      <div class="joins">
                        <div class="dot start-dot" />
                        <div class="line" />
                      </div>
                      <div class="joins">
                        <div class="line" />
                        <div class="dot end-dot" />
                      </div>
                      <div>
                        {{ outboundLeg.departs_at.date | time }}
                      </div>
                      <div>
                        {{ outboundLeg.arrives_at.date | time }}
                      </div>
                      <q-icon v-if="index < (summary.outbound.legs.length - 1)" class="change" name="fas fa-clock" />
                      <div v-else style="grid-area:icon" />
                      <sub
                        v-if="index < (summary.outbound.legs.length - 1)"
                        style="grid-area:change-time"
                      >
                        {{ changeoverTime(outboundLeg.arrives_at.date, summary.outbound.legs[index + 1].departs_at.date) | minutesToHours }}
                      </sub>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(outboundLeg, index) in summary.outbound.legs"
                :key="outboundLeg.flight_number"
                :data-testid="`outbound-${index}`"
              >
                <div class="title bg-primary" @click="toggleHidden('outbound', index)">
                  <span>Outbound Flight {{ index + 1 }}</span>
                  <q-icon :name="`fas fa-chevron-${dropdowns.outbound[index].hidden ? 'right' : 'down'}`" />
                </div>
                <transition name="dropdown">
                  <div v-if="!dropdowns.outbound[index].hidden" class="flight-summary-two">
                    <img src="~assets/heart-logo-icon.png" class="logo">
                    <div class="start-iata" v-text="outboundLeg.route.origin.iata" />
                    <div class="changes" />
                    <div class="end-iata" v-text="outboundLeg.route.destination.iata" />
                    <div class="flight-num">
                      <sub>Flight num</sub>
                      {{ outboundLeg.flight_number }}
                    </div>
                    <div class="leg-start-dot start-dot dot" />
                    <div class="leg-line line" />
                    <div class="leg-end-dot end-dot dot" />
                    <div class="start-time">
                      {{ outboundLeg.departs_at.date | time }}
                    </div>
                    <div class="journey-time">
                      {{ outboundLeg.duration | minutesToHours }}
                    </div>
                    <div class="end-time">
                      {{ outboundLeg.arrives_at.date | time }}
                    </div>
                  </div>
                </transition>
              </div>
            </q-card-section>
            <q-card-section v-if="summary.return">
              <div
                class="flight-summary-one"
              >
                <div class="title bg-primary" v-text="'Return Summary'" />
                <div class="timeline">
                  <div
                    v-for="(returnLeg, index) in summary.return.legs"
                    :key="returnLeg.flight_number"
                    :data-testid="`return-summary-${index}`"
                  >
                    <div class="timeline-section">
                      <div v-text="returnLeg.route.origin.iata" />
                      <div v-text="returnLeg.route.destination.iata" />
                      <div class="joins">
                        <div class="dot start-dot" />
                        <div class="line" />
                      </div>
                      <div class="joins">
                        <div class="line" />
                        <div class="dot end-dot" />
                      </div>
                      <div>
                        {{ returnLeg.departs_at.date | time }}
                      </div>
                      <div>
                        {{ returnLeg.arrives_at.date | time }}
                      </div>
                      <q-icon v-if="index < (summary.return.legs.length - 1)" class="change" name="fas fa-clock" />
                      <div v-else style="grid-area:icon" />
                      <sub
                        v-if="index < (summary.return.legs.length - 1)"
                        style="grid-area:change-time"
                      >
                        {{ changeoverTime(returnLeg.arrives_at.date, summary.return.legs[index + 1].departs_at.date) | minutesToHours }}
                      </sub>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(returnLeg, index) in summary.return.legs"
                :key="returnLeg.flight_number"
                :data-testid="`return-${index}`"
              >
                <div class="title bg-primary" @click="toggleHidden('return', index)">
                  <span>Return Flight {{ index + 1 }}</span>
                  <q-icon :name="`fas fa-chevron-${dropdowns.return[index].hidden ? 'right' : 'down'}`" />
                </div>
                <transition name="dropdown">
                  <div v-if="!dropdowns.return[index].hidden" class="flight-summary-two">
                    <img src="~assets/heart-logo-icon.png" class="logo">
                    <div class="start-iata" v-text="returnLeg.route.origin.iata" />
                    <div class="changes" />
                    <div class="end-iata" v-text="returnLeg.route.destination.iata" />
                    <div class="flight-num">
                      <sub>Flight num</sub>
                      {{ returnLeg.flight_number }}
                    </div>
                    <div class="leg-start-dot start-dot dot" />
                    <div class="leg-line line" />
                    <div class="leg-end-dot end-dot dot" />
                    <div class="start-time">
                      {{ returnLeg.departs_at.date | time }}
                    </div>
                    <div class="journey-time">
                      {{ returnLeg.duration | minutesToHours }}
                    </div>
                    <div class="end-time">
                      {{ returnLeg.arrives_at.date | time }}
                    </div>
                  </div>
                </transition>
              </div>
            </q-card-section>
            <q-separator />
            <q-card-section class="price-breakdown">
              <strong>Unit price:</strong>{{ summary.price_breakdown.unit_price.text }}
              <strong>Total:</strong>{{ summary.price_breakdown.total_price.text }}
            </q-card-section>
          </q-card>
        </div>
        <payment-method-buttons
          :total-amount="summary.price_breakdown.total_price.amount"
          :requester="stash.parameters.requester"
          :journey="{ name: summary.outbound.journey.name }"
          :booking-token="stash.booking_token"
          travel-type="Business"
        />
        <sub
          class="col-12 text-center text-faded"
          style="margin-top: 8px"
          v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
        />
      </div>
    </div>
  </q-page>
</template>

<script>
import { summary } from 'api/srf'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'
import store from 'store'
import i18n from 'i18n'
import paymentMethodButtons from 'pages/payments/payment-method-buttons'
import date from 'utils/date-time'

export default {
  filters: {
    time (val) {
      return date.toCivilTime(val)
    },
    minutesToHours (val) {
      if (val < 60) return `${val}m`
      let m = val
      let h = 0
      while (m >= 60) {
        h++
        m -= 60
      }
      return `${h}h ${m}m`
    }
  },
  components: { paymentMethodButtons },
  data () {
    return {
      dropdowns: {
        outbound: [],
        return: []
      }
    }
  },
  computed: {
    partner () {
      return this.$store.getters.partner
    },
    stash () {
      return this.$store.getters['ondemand/stash']
    },
    summary () {
      return this.stash.summary
    }
  },
  beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.searching.flight'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    next(vm => {
      const {
        booking_token,
        outbound_service_token,
        return_service_token
      } = vm.$store.getters['ondemand/stash']
      summary(booking_token, { outbound_service_token, return_service_token })
        .then(({ data }) => {
          vm.$store.dispatch('ondemand/stash', { summary: data.summary })
          data.summary.outbound.legs.forEach(leg => vm.dropdowns.outbound.push({ hidden: true }))
          if (data.summary.return) {
            data.summary.return.legs.forEach(leg => vm.dropdowns.return.push({ hidden: true }))
          }
          loading.stop()
        })
        .catch(err => {
          handleErrors(err)
          next(from)
          loading.stop()
        })
    })
  },
  methods: {
    toggleHidden (direction, index) {
      this.dropdowns[direction][index].hidden = !this.dropdowns[direction][index].hidden
    },
    changeoverTime (arrive, depart) {
      return date.getDateDiff(depart, arrive, 'minutes').minutes
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 786px
button
  width 100%

.flight-summary-one
  display flex
  flex-direction column
  margin 15px 0 0
  padding-bottom 10px
  box-shadow 0 1px 5px rgba(0 0 0 0.2), 0 2px 2px rgba(0 0 0 0.14), 0 3px 1px -2px rgba(0 0 0 0.12)

.timeline
  display grid
  grid-auto-flow column
  align-items center
  padding 15px 0 15px 0
  margin-right -50px

.timeline-section
  display grid
  grid-template-columns 1fr 1fr 50px
  grid-template-rows repeat(3, auto)
  grid-template-areas \
    "none none icon" \
    "none none icon" \
    "none none change-time"
  grid-row-gap 5px
  align-items center
  justify-content center
  text-align center

.joins
  align-self stretch
  display grid
  grid-template-columns 1fr 1fr
  align-items center
  justify-content stretch

.change
  grid-area icon
  justify-self center
  align-self flex-end

.flight-summary-two
  height 106px
  overflow hidden
  display grid
  grid-template-rows 1fr 10px 1fr
  grid-template-columns 100px auto 1fr auto 5px auto
  grid-template-areas \
  "logo start-iata changes end-iata separator flight-num" \
  "logo start-dot line end-dot separator flight-num" \
  "logo start-time journey-time end-time separator ..."
  align-items center
  justify-items center
  box-shadow 0 1px 5px rgba(0 0 0 0.2), 0 2px 2px rgba(0 0 0 0.14), 0 3px 1px -2px rgba(0 0 0 0.12)

.title
  grid-area title
  justify-self stretch
  padding 10px
  display flex
  justify-content space-between
  align-items center
  box-shadow 1px 0 rgba(0 0 0 0.3), -1px 0 rgba(0 0 0 0.3)

.logo
  grid-area logo
  width 75px

.start-iata
  grid-area start-iata
  padding 0 30px

.end-iata
  grid-area end-iata
  padding 0 30px

.changes
 grid-area changes

.start-time
  grid-area start-time

.end-time
  grid-area end-time

.dot
  height 6px
  width 6px
  background #dcdcdc
  border-radius 50%

.start-dot
  justify-self end

.line
  width 100%
  height 2px
  background #dcdcdc

.end-dot
  justify-self start

.leg-start-dot
  grid-area start-dot

.leg-line
  grid-area line

.leg-end-dot
  grid-area end-dot

.journey-time
  grid-area journey-time
  align-self flex-end
  margin-right 4%
  font-weight 600

.flight-num
  grid-area flight-num
  padding 5px 15px 0
  display flex
  flex-direction column
  align-items center
  justify-content center

.price
  grid-area price
  padding 0 15px 5px
  display flex
  flex-direction column
  align-items center
  justify-content center

.dropdown-enter
  height 0px

.dropdown-leave-to
  height 0px

.dropdown-enter-active
  transition height 0.5s

.dropdown-leave-active
  transition height 0.5s

.price-breakdown
  padding-left 25px
  font-size 110%
  display grid
  grid-template-columns 90px auto
  justify-items start
  grid-row-gap 5px

  </style>
