import { render, staticRenderFns } from "./result-card.vue?vue&type=template&id=e57df864&scoped=true&"
import script from "./result-card.vue?vue&type=script&lang=js&"
export * from "./result-card.vue?vue&type=script&lang=js&"
import style0 from "./result-card.vue?vue&type=style&index=0&id=e57df864&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e57df864",
  null
  
)

export default component.exports