<template>
  <q-page>
    <div v-if="results" role="listbox" class="card-container">
      <result-card
        v-for="result in results[leg]"
        :key="result.token"
        :result="result"
        @click="token => setSelected(token)"
      />
    </div>
  </q-page>
</template>

<script>
import { Duration } from 'luxon'
import store from 'store'
import { search } from 'api/srf'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import i18n from 'i18n'
import date from 'utils/date-time'
import ResultCard from './result-card.vue'

export default {
  filters: {
    timeFilter (val) {
      return date.toCivilTime(val)
    }
  },
  components: { ResultCard },
  computed: {
    stash () {
      return this.$store.getters['ondemand/stash']
    },
    results () {
      return this.stash.results
    },
    leg () {
      return this.stash.leg
    },
    selected: {
      get () {
        return this.leg === 'outbound'
          ? this.stash.outbound_service_token
          : this.stash.return_service_token
      },
      set (token) {
        this.leg === 'outbound'
          ? this.$store.dispatch('ondemand/stash', { outbound_service_token: token })
          : this.$store.dispatch('ondemand/stash', { return_service_token: token })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.searching.flight'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    next((vm) => {
      const { parameters } = vm.$store.getters['ondemand/stash']
      search({
        ...parameters,
        requester: parameters.requester.value,
        user: parameters.user.value
      })
        .then(({ data }) => {
          const results = {
            outbound: [],
            return: []
          }
          Object.keys(data.services.outbound.options).forEach(key => {
            results.outbound.push(data.services.outbound.options[key])
          })
          if (data.services.return.options) {
            Object.keys(data.services.return.options).forEach(key => {
              results.return.push(data.services.return.options[key])
            })
          }
          vm.$store.dispatch('ondemand/stash', { results, leg: 'outbound', booking_token: data.token })
          loading.stop()
        })
        .catch((err) => {
          handleErrors(err)
          loading.stop()
          next(from)
        })
    })
  },
  methods: {
    setSelected (selected) {
      this.selected = selected
    },
    duration (minutes) {
      const duration = Duration.fromObject({ minutes }).toFormat('h m')
      return `${duration.split(' ')[0]}${this.$t('abbreviation.hours')} ${duration.split(' ')[1]}${this.$t('abbreviation.minutes')}`
    }
  }
}
</script>

<style lang="stylus" scoped>
.card-container
  display flex
  flex-direction column
  max-width 600px
  margin auto

.selected
  animation click 0.1s

@keyframes click
  0%
    transform scale(1, 1)
  50%
    transform scale(0.95, 0.95)
  100%
    transform scale(1, 1)
</style>
